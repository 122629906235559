import React, { FunctionComponent, useContext } from 'react'
import { FeedWidgetWrapper, WidgetHeader } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { hiddenScrollbar } from '@wh/common/chapter/components/hiddenScrollbar'
import { PopularCategoriesWidgetType } from '@bbx/common/types/UserFeed'
import Gridview from '@wh-components/icons/Gridview'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'

interface PopularCategoriesWidgetProps {
    widget: PopularCategoriesWidgetType
}

export const PopularCategoriesWidget: FunctionComponent<PopularCategoriesWidgetProps> = ({ widget }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const popularCategoriesBackgroundColor =
        advertisingState.pageModifications.backgroundColors?.['startpage-popular-categories'] ?? 'palette.shark'
    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']

    const taggingData = useFeedEmptyTaggingData()

    return (
        <FeedWidgetWrapper type={widget.type}>
            <WidgetHeader icon={Gridview} backgroundColor="palette.primary.main" heading="Beliebte Kategorien" />
            <Box
                display="flex"
                justifyContent={{ tablet: 'space-between' }}
                overflow={{ phone: 'auto', tablet: 'visible' }}
                paddingRight={{ phone: 'm', tablet: 0 }}
                paddingLeft="m"
                marginTop="m"
                gap={{ phone: 'm', tablet: '0' }}
                css={css`
                    margin-left: -${(p) => p.theme.space.m}px;
                    ${hiddenScrollbar}
                `}
            >
                {widget.popularCategories.map((category) => {
                    return (
                        <ClientRoutingAnchorLink
                            type="anchor"
                            underline="none"
                            key={category.text}
                            href={category.webLink.uri}
                            onClick={() => {
                                callActionEvent('startpage_popular_category_click', taggingData, {
                                    popular_category_label: category.text,
                                })
                            }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                fontWeight="bold"
                                flex={1}
                                flexDirection="column"
                                width={{ phone: 100, tablet: 80 }}
                            >
                                <AspectRatioBox
                                    borderRadius="50%"
                                    backgroundColor={popularCategoriesBackgroundColor}
                                    ratio={1}
                                    width="100%"
                                >
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <ResponsiveImage src={category.imageUrl} position="absolute" />
                                    </Box>
                                </AspectRatioBox>

                                <Text marginTop="s" fontSize="s" color={color ?? 'palette.verydarkgrey'} textAlign="center">
                                    {category.text}
                                </Text>
                            </Box>
                        </ClientRoutingAnchorLink>
                    )
                })}
            </Box>
        </FeedWidgetWrapper>
    )
}
